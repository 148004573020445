/* Estilos generales */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  z-index: 1000;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding-top: 1rem;
}
/* Ocultar el botón hamburguesa en modo escritorio */
.menu-toggle {
  display: none; /* Oculto por defecto */
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  height: 40px;
  margin-right: 0.5rem;
}

.logo h1 {
  font-size: 1.5rem;
  color: #3f51b5;
  font-weight: bold;
}

.nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav ul li {
  margin: 0 1rem;
}

.nav ul li a {
  text-decoration: none;
  color: #003366;
  font-size: 1rem;
  transition: color 0.3s;
}

.nav ul li a:hover {
  color: #0056b3;
}

.register-button {
  background-color: #3F51B5;
  color: #ffffff !important;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.register-button:hover {
  background-color: #2c3e50;
}

/* === Estilos Responsive para Mobile === */
@media (max-width: 768px) {
  .header {
    height: auto; /* Ajustar la altura */
    padding: 0.5rem 1rem;
  }

  .container {
    flex-direction: row; /* Colocar elementos verticalmente */
    width: 100%;
  }

  .second-container {
    flex-direction: column !important;  /* Colocar elementos verticalmente */
  }

  .logo {
    display: flex;
    align-items: center;
  }
  
  .logo img {
    height: 40px;
    margin-left: 0.5rem; /* Ajustar el espacio si es necesario */
  }


  .nav ul {
    flex-direction: column; /* Menú vertical */
    width: 100%;
    margin-top: 1rem;
    display: none; /* Ocultar el menú por defecto */
  }

  .nav ul li {
    margin: 0.5rem 0;
  }

  .nav ul li a {
    font-size: 1.1rem;
    color: #003366;
  }

  .nav.open ul {
    display: flex; /* Mostrar el menú cuando el estado sea "open" */
  }

  /* Botón estilo hamburguesa */
  .menu-toggle {
    order: -1; /* Mueve el botón al inicio */
    display: flex; /* Mostrar el menú hamburguesa */
    align-items: center;
    cursor: pointer;
    font-size: 1.5rem;
    color: #3f51b5;
    margin-right: 1rem; /* Espacio entre el toggle y el logo */
  }

  .register-button {
    width: 100%;
    text-align: center;
    margin-top: 1rem;
  }

  .welcome-section {
    margin-top: 0rem !important;
  }

  .text-content{
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .features-grid{
    margin-top: 0% !important; 
  }
  
  p{
    margin-bottom: 1rem !important;
  }
}

